.mainContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url("../assets/background.png");
    background-size: 100%;
    color: white;
    overflow: hidden;
    position: relative;
}

.formContainer {
    width: 100vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    row-gap: 5vh;
    justify-content: center;

    align-items: center;
    justify-items: center;
    border-radius: 5px;
    overflow: hidden;
}

.inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 3vh;
    justify-content: center;
}

.logoPurple {
    width:15vw;
    position:absolute;
    top:5vh;
    left:10vh;
}

.logoAngelini {
    width:12vw;
    position:absolute;
    bottom:5vh;
    right:10vh;
    fill: white;
}

@media only screen and (max-width: 600px) {
    .logoPurple {
        width:50vw;
        position:absolute;
        top:2vh;
        left: 25vw;
    }

    .logoAngelini {
        width:35vw;
        position:absolute;
        bottom:4vh;
        right:36%;
        fill: white;
    }
}


