@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: "Barlow Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: "Barlow Condensed", sans-serif;
}

h2 {
  font-family: "Barlow Condensed", sans-serif;
}

h3 {
  font-family: "Barlow Condensed", sans-serif;
}

p {
  font-family: "Barlow Condensed", sans-serif;
  white-space: pre-wrap;
}

li {
  font-family: "Barlow Condensed", sans-serif;
  font-weight: bold;
}

ul {
  font-family: "Barlow Condensed", sans-serif;
}

.no-hover-effect:hover {
  background-color: transparent;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
