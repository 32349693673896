.videoHeader {
    display: flex;
    width: 57vw;
    flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    row-gap: 0;
    margin-bottom: 2vh;
    text-align: right;
}

.video {
    width: 57vw;
    height: 32vw;
}

@media only screen and (max-width: 600px) {
    .videoHeader {
        display: flex;
        width: 80vw;
        margin-bottom: 2vh;
    }

    .video {
        width: 80vw;
        height: 50vw;
    }
}