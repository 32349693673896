.questionContainer {
    width: 80vw;
    height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.option {
    border: 1px solid white;
    border-radius: 20px;
    width: 30vw;
    margin-bottom:3vh;
    font-size: 1.5vw;
    font-weight: normal;
}

.question {
    margin-bottom: 2vh;
    width: 35vw;
    font-size: 2vw;
    white-space: pre-wrap;
}



.option.selected {
    background-color: rgba(201, 107, 255, 0.38);
}

@media (hover: hover){
    .option:hover {
        background-color: rgba(201, 107, 255, 0.38);
    }
}

@media only screen and (max-width: 600px) {
    .question {
        margin-bottom: 2vh;
        font-size: 5.5vw;
        width: 80vw;
        white-space: pre-wrap;
    }

    .option {
        border: 1px solid white;
        border-radius: 20px;
        width: 80vw;
        margin-bottom:3vh;
        font-size: 4.5vw;
    }
}