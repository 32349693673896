.score {
    font-size: 20vw;
    margin: 0;
    margin-top: -6vh;
}

@media only screen and (max-width: 600px) {
    .score {
        font-size: 60vw;
        margin-top: -6vh;
    }
}