
.rankingHeader {
    width: 45vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.countryHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 0.5vw;
}

.rankingRow {
    width: 45vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
}

.flagImage {
    width: 2vw;
    height: 2vw;
}

.score {
    font-size: 2vw;
}

.percentage {
    font-size: 1.2vw;
}

.rankBar {
    border-top: 2px dotted white;
    width: 35%;
    margin-top: 3.5vh;
}

@media only screen and (max-width: 600px) {
    .rankingHeader {
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 3vw;
    }

    .countryHeader {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 0.5vw;
    }

    .rankingRow {
        width: 90vw;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 3.5vw;
        font-weight: bold;
    }

    .flagImage {
        width: 5vw;
        height: 5vw;
    }

    .rankBar {
        border-top: 2px dotted white;
        width: 5%;
        margin-top: 2.5vh;
    }

    .rankingCountry {
        font-size: 4vw;
    }

    .score {
        font-size: 6vw;
    }

    .percentage {
        font-size: 3vw;
    }
}